.introsection-container{

    height: 75vh;
    background: url('../../../assets/dataana.svg') 
    

    no-repeat center center/cover;
    
}
.introsection{
    width: 1200px;
    margin: 0px auto;
   
   display: flex;
 
    justify-content: flex-start;
    
}
.intro-text{
    width: 60%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(6px);
   
}
.intro-text h1{
    
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: white;
    margin-bottom: 50px;
}
.intro-text button{
 
    width: 220px;
    height: 55px;
    border: none;
    color: black;
    font-family: 'Lora';
    font-size: 16px;
    background: #F8B810;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    font-weight: 500;
}


@media screen and (max-width: 575px) {
    .introsection-container{
        width: 100%;
height:  316px;
    }
    .introsection{
        width: 100%;
       
    }
    .intro-text{
        width: 100%;
        margin: 0px auto;
        height: 316px;
        padding: 0px 10%;
        backdrop-filter: blur(2px);
    }
    .intro-text h1{
    margin-top: 66px;
        font-weight: 500;
        width: 200px;
        
        margin-bottom: 14px;
font-size: 15px;
line-height: 17px;
       
    }
    .intro-text button{
        font-weight: 600;
        font-size: 10px;
        line-height: 13px;        
        color: rgba(0, 0, 0, 0.9);
        width: 93px;
        height: 26px;
    }
}