.invent-container{
    width: 1200px;
    margin: 150px auto;
}
.invent{
    display: flex;
    margin-bottom: 100px;
}
.invent-left img{
    width: 600px;
}  

.invent-right{

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    transform: translate(-100px ,100px);
    width: 628px;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(91, 87, 87, 0.06);

}
.invent-right-odd{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    transform: translate(100px ,75px);
    width: 628px;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(91, 87, 87, 0.06);

}
.invent-right h1{
    font-size: 40px;
    font-weight: 500;
}
.invent-right p{
    line-height: 27px;
    margin: 40px 0px;
    font-weight: 400;
    font-weight: 400;
    font-size: 18px;

color: rgba(0, 0, 0, 0.7);
}
.invent-right button{

    width: 150px;
    height: 55px;
    border: none;
    color: white;
    font-family: 'Avenir Next', sans-serif;
    background: #4372CC;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
}
.invent-right-odd h1{
    font-size: 40px;
    font-weight: 500;
}
.invent-right-odd p{
    line-height: 27px;
    margin: 40px 0px;
}
.invent-right-odd button{
    width: 150px;
    height: 55px;
    border: none;
    color: white;
    font-family: 'Avenir Next', sans-serif;
    background: #4372CC;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
}

@media screen and (max-width: 575px) {
    .invent-container{
        width: 90%;
        margin: 0px auto;
        overflow-x: auto;
    }
    .invent{
        display: flex;
        margin-bottom: 0px;
    }
    .invent-right{
        width: 294px;
        height: 232px;
        padding:0px 20px 20px 20px ;
        transform: translate(-250px , 100px);
        background: #FFFFFF;
        border: 2px solid rgba(107, 101, 101, 0.1);
        box-shadow: 1px 4px 4px 2px rgba(218, 205, 205, 0.13);
    }
    .invent-right h1{
      
        display: none;
    }
    .invent-right p{
        width: 252px;
        height: 144px;
        transform: translateY(-40px);
       
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;        
        color: rgba(0, 0, 0, 0.7);
    }
    .invent-left{
        width: 90%;
        margin: 50px auto;
    }
    .invent-left img{
        width: 300px;
       
    }
}