.selection{
    width: 1200px;
    margin: 150px auto;
    height: auto;
}
.selection-heading-flex{
    display: flex;
    justify-content: space-between;
    transition: .3s ease-in-out;
    margin-bottom: 100px;
    padding: 0px 5%;
}
.selection-heading-flex h1{
    font-weight: 500;
    transition: .3s ease-in-out;
    height: 50px;
    cursor: pointer;
}

@media screen and (max-width: 575px) {
    .selection{
        width: 100%;

    }
    .selection-heading-flex h1{
        font-size: 12px;
    }
}

.selection-heading-flex{
    width: 100%;
    overflow-x: auto;
}