.cont{
    width: 1200px;
    margin: 150px auto;
   
}
.cont-flex{
    display: flex;
    width: 900px;
    margin: 0px auto;
}
.cont-heading{
    margin-bottom: 50px;
}
.cont-heading h1{
    text-align: center;
   font-weight: 600;
   font-size: 55px;
   line-height: 70px;
   margin-bottom: 15px;
   
    z-index: 1000;
}
.cont-heading p{
    text-align: center;
  
    font-weight: 600;
font-size: 22px;
line-height: 30px;


}

.cont-box-1{
    width: 517px;
    height: 687px;
    background: rgba(236, 236, 236, 0.24);
    padding: 30px;
}
.cont-box-2{
    width: 435px;
    height: 480px;
    background: rgba(236, 236, 236, 0.24);
    padding: 30px;
    transform: translate(-90px , 90px);
    border: 10px solid white;
}
.cont-box-1 p{
    font-weight: 400;
font-size: 18px;
line-height: 26px;

color: #000000;
}
.cont-form label{
    display: block;
    margin: 30px 0px;
}
.cont-form input{
    width: 70%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid  rgba(217, 217, 217, 0.4);
    background: transparent;
    outline: none;
    padding-left: 20px;
    padding-bottom: 5px;
    font-size: 16px;
    font-family: 'Lora';
}
.cont-form button{
    width: 240px;
    height: 55px;
    font-family: 'Lora';
    background: #F8B810;
    border: none;
    font-size: 16px;
    margin-top: 50px;
}
.cont-items{
    display: flex;
}
.cont-items{
    margin: 50px 0px;
}
.cont-items img{
width: 25px;
margin: 10px;
}
.cont-items p{
    align-self: center;
}

@media screen and (max-width: 575px) {
    .cont{
        width: 100%;
        margin: 50px auto;
    }
    .cont-heading{
        padding: 0px 30px;
    }
    .cont-heading h1{
        text-align: center;
       font-weight: 600;
       font-size: 40px;
     display: none;
       margin-bottom: 15px;
       
        z-index: 1000;
    }
    .cont-heading p{
        text-align: center;
       
        font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    
    
    }
    .cont-flex{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-box-1{
        width: 95%;
        margin-bottom: 20px;
    }
    .cont-box-2{
        width: 100%;
        transform: none;
    }
}