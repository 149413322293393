.signup{
    display: flex;
    width: 1200px;
    margin: 150px auto;
}
.signup-left img{
    width: 600px;
}
.signup-right{
    width: 763px;
    height: 250px;
    transform: translate(-100px , 150px);
  
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signup-right h3{
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 20px;
    color: rgba(18, 18, 18, 0.7);
}
.signup-right p{
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;

/* identical to box height */



}
.input{
    width: 500px;
height: 60px;


background: rgba(217, 217, 217, 0.18);

}
.input input{
    background: rgba(217, 217, 217, 0.18);
    outline: none;
    padding-left: 30px;
    height: 60px;
    width: 70%;
    border: 1px solid rgba(37, 36, 36, 0.04);
    font-family: 'Lora';
}
.input button{
    height: 60px;
    color: white;
    font-family: 'Lora', serif;
    width: 30%;
    font-size: 17px;
    border: none;
    background: #F8B810;
    color: black;
}

@media screen and (max-width: 575px) {
    .signup{
        width: 100%;
        flex-direction: column;
        margin: 30px auto;
    }
    .signup-left{
        width: 90%;
        margin: 0px auto;
    }
    .signup-left img{
        width: 100%;
    }
    .signup-right h3{
        font-weight: 500;
font-size: 12px;
line-height: 10px;

color: rgba(18, 18, 18, 0.7);
        width: 90%;
    }
    .signup-right p{
        font-size: 16px;
        width: 90%;
        text-align: left;
    }
    .signup-right{
        width: 100%;
        transform: none;
    }
    
   

    .input{
        width: 90%;
        height: 32px;
    }
    .input input{
        height: 32px;
    }
    .input button{
        font-size: 13px;
        height: 32px;
    }
   
}