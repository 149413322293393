.hamburger{
    display: none;
}
.burger-line{
    display: none;
}
.static{
        height: 100vh;
        overflow-y: unset;
        position: fixed;
}
.modal-logout{
        background-color: #FFFFFF;
   
        position: absolute;
        z-index: 18000;
        width: 324px;

        left: calc((100% - 324px)/2);
        top: calc((125% - 228px)/2) ;
        border-radius: 10px;
       
        height: 228px;
}
.cover{
    
display: none;
}

@media screen and (max-width: 575px) {
    .cover{
    
        position: absolute;
    display: block;
        background-color: rgba(0,0,0,.4);
        height: 200vh;
        left: 0%;
        top: 50%;
        overflow-x: hidden;
        transform: translate(-50%,-50%);
        width: 200vw;
        z-index: -1;
      
    }
    .hamburger{
      display: block;
     left: 0px;
     top: 80px;
       position: absolute;
        width: 100%;
        height: 100vh;
        background-color:#0E2A4A;
     
     
    }
    .burger-heading h3{
        font-weight: 500;
font-size: 18px;
line-height: 27px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: -0.235443px;

/* black/main */
margin-bottom: 8px;
color: #121212;
    }
    .burger-heading p{
        font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: -0.3px;

/* text */

color: #757575;
    }
    .burger-line{
        display: block;
        align-self: center;
        position: absolute;
        top:30px;
        right: 0;
        z-index: 17000;
        
    }
    .burger-links{
       padding-top: 100px;
    }
    .burger-line img{
        width: 20px;
     
    }
    .burger-links a img{
            filter: invert(50%) sepia(8%) saturate(0%) hue-rotate(161deg) brightness(88%) contrast(80%);
            width: 20px;
    }
    .hamburger-logout a img{
        filter: invert(0%);
       
    }
    .burger-line img{
        width: 20px;
    }
    .burger-heading {
        margin-left: 30px;
    }
     .hamburger-logout{
        margin-top: 33px;
        margin-left: 30px;
     } 
    .hamburger-logout a{
        display: flex;
    }
    .hamburger-logout p{
        color: #FF3737;
        font-size: 17px;
        line-height: 26px;
        font-weight: 400;
        margin-left: 14px;
    }
.dashboard-sidebar-links{
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 25px;

}
    


    .slide-in-elliptic-left-fwd {
       
        -webkit-animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    .fade-in-left {
    
        display: flex;
        justify-content: center;
        flex-direction: column;
        -webkit-animation: fade-in-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    /* ----------------------------------------------
 * Generated by Animista on 2022-9-1 23:25:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  
   /* ----------------------------------------------
 * Generated by Animista on 2022-9-1 22:35:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation slide-in-elliptic-left-fwd 
 * ----------------------------------------
 */
 .fade-out-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
	-webkit-animation: fade-out-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: fade-out-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-9-1 23:26:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
  }
  @keyframes fade-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
  }
  
@-webkit-keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }


  .slide-out-elliptic-left-bck {
    display: flex;
    justify-content: center;
    flex-direction: column;
	-webkit-animation: slide-out-elliptic-left-bck 0.7s ease-in both;
	        animation: slide-out-elliptic-left-bck 0.7s ease-in both;
}


  /* ----------------------------------------------
 * Generated by Animista on 2022-9-1 22:36:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-elliptic-left-bck 
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-left-bck {
    0% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 2000px 50%;
              transform-origin: 2000px 50%;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px) rotateY(30deg) scale(0);
              transform: translateX(-1000px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 1;
    }
  }
  @keyframes slide-out-elliptic-left-bck {
    0% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 2000px 50%;
              transform-origin: 2000px 50%;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px) rotateY(30deg) scale(0);
              transform: translateX(-1000px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 1;
    }
  }
.modal-confirm{
        margin: 0px 24px 36px 24px;
}
  .modal-confirm p{
        font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height */
margin: 24px 0px 36px 0px;

letter-spacing: -0.3px;

/* text */
text-align: center;
color: #757575;
  }
  .confirm-btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
  }
  .confirm-btns button:nth-child(1){
        width: 90px;
height: 42px;
font-family: 'Poppins';
font-size: 14px;
border: none;
color: #757575;
background: #F8F8F8;
border-radius: 5px;
        
  }
  .confirm-btns button:nth-child(2){
        width: 90px;
height: 42px;
font-family: 'Poppins';
font-size: 14px;
border: none;
color: white;
background: #FF3737;
border-radius: 5px;
        
  }
  
}