.initiative-body{
    width: 1200px;
    margin: 0px auto;

}
.initiative-left img{
    width: 500px;
}

.initiative-box{
    display: grid;
    grid-template-columns: repeat(2,auto);
    gap: 200px;
    margin-bottom: 150px;
}
.initiative-box-odd{
    display: grid;
    grid-template-columns: repeat(2,auto);
    gap: 200px;
    margin-bottom: 150px;
}

.initiative-heading h1{
    text-align: center;
    font-size: 45px;
    font-weight: 500;
    margin: 150px 0px;
    display: block;
}
.initiative-right{
    display: flex;
    flex-direction: column;
    justify-content: center;

    
}
.initiative-right h1{
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 15px;
}
.initiative-right p{
    font-size: 19px;
    line-height: 30px;
    width: 100%;
}

.blogs-heading{
    margin-bottom: 30px;
}

.blogs-heading h1{
    text-align: center;
    font-size: 45px;
    font-weight: 500;
}
.blogs-text-container{
 
    display: flex;
    width: 1000px;
    margin: 100px auto;
    justify-content: space-between;
}
.blogs-body{
    width: 500px;
}
.blogs-text-container p{
    font-size: 19px;
    line-height: 30px;
    width: 100%;
    margin-bottom: 30px;
}
.blogs-text-container a{
  transform: translateY(30px);
    text-decoration: none;
    color: black;
    padding: 10px 20px;
    font-family: 'Lora';
    background: #F8B810;
    border: none;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}
.blogs-img-box img{
   width: 400px;
}
.blogs-img-box{
    width: 130px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.initiative-left h4{
    display: none;
}
@media screen and (max-width: 575px) {
    .blogs-text{
        width: 90%;
        margin: 0px auto;
    }
    .initiative-heading h1{
        font-weight: 600;
font-size: 26px;
line-height: 33px;

color: rgba(18, 18, 18, 0.93);
margin: 50px auto;
    }
    .blogs-text-container{
        flex-direction: column;
        width: 90%;
    }
    .blogs-img-box img{
        width: 250px;
    }
    .blogs-body{
        width: 100%;
    }
    .blogs-text-container p{
        font-size: 16px;
        font-weight: 400;
        width: 90%;
        line-height: 30px;  
        color: rgba(18, 18, 18, 0.6);
        margin: 35px 0px;
    }
    .initiative-body{
        width: 100%;
    }
    .initiative-right{
        padding: 0px 5%;
    }
    .initiative-left{
        padding: 0px 5%;
    }
    .initiative-left img{
        width: 100%;
    }
    .initiative-box{
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }
    .initiative-box-odd{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 50px;
    }
    .initiative-right p{
        font-size: 16px;
        font-weight: 400;
        width: 90%;
        line-height: 30px;  
        color: rgba(18, 18, 18, 0.6);
        margin: 35px 0px;

        
    }
    .initiative-right h1{
        margin-top: 30px;
        font-size: 30px;
        font-weight: 500;
        width: 100%;
        color: rgba(18, 18, 18, 0.93);
        display: none;
        
    }
    .initiative-left h4{
        font-weight: 500;
font-size: 16px;
line-height: 20px;
margin-bottom: 26px;
color: rgba(18, 18, 18, 0.93);
display: block;
    }
}