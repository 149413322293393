@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {

  font-family: 'Lora', serif;
}

@media screen and (max-width: 575px)  {
    body{
      width: 100%;
      overflow-x: hidden;
    }
}

