.about-c-bottom h1{
    font-weight: 500;
font-size: 45px;
line-height: 41px;
color: rgba(18, 18, 18, 0.93);
text-align: center;
margin: 50px 0px;
}
.about-c-bottom p{
    margin-bottom: 50px;
    font-weight: 500;
font-size: 25px;
line-height: 32px;

color: rgba(18, 18, 18, 0.6);
    
}

@media screen and (max-width: 575px) {
    .about-c-bottom h1{
        font-size: 30px;
    }
    .about-c-bottom p{
        font-size: 16px;
        line-height: 25px;
    margin: 0px 5%;
    }
}