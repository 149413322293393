.get{
    width: 800px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.get-container{
    background: rgba(217, 217, 217, 0.15);
     padding: 70px 0px;
     margin-bottom: 50px;
}
.get h1{
    font-size: 45px;
    font-weight: 600;
 
    line-height: 64px;

    color: rgba(0, 0, 0, 0.9);
}
.get p{
    font-size: 20px;
    font-weight: 500;
  margin: 20px 0px;
    line-height: 38px;
    
    color: rgba(0, 0, 0, 0.7);
}
.get button{
    width: 240px;
    height: 55px;
    font-family: 'Lora';
    background: #F8B810;
    border: none;
    font-size: 16px;
}

@media screen and (max-width: 575px) {
    .get{
        width: 100%;

    }
    .get h1{
        font-size: 30px;
    }
    .get p{
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        width: 90%;
    }
}