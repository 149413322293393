.footer-container{
    background: #0E2A4A;
    height: auto;
    padding: 100px 0px;
}
.footer{
    width: 1200px;
    margin: 0px auto;
}
.footer h1{
    font-weight: 500;
    color: white;
}

.footer-top-left p{
    color: white;
    margin-top: 20px;
    font-size: 20px;
}
.footer-top-right p{
    color: white;
    margin-top: 20px;
    font-size: 20px;
    
}
.footer-top-left img{
    transform: translateX(-30px);
}
.footer-top-right-line p{
    color: white;
    margin-top: 20px;
    font-size: 20px;
    text-decoration: underline;
    text-underline-offset: 8px;
    position: relative;
    cursor: pointer;
}
.footer-top-socials h1{
    text-align: right;
}
.footer-top-socials p{
    color: white;
    margin-top: 20px;
    font-size: 20px;
}
.socials-container{
    margin-top: 15px;
    display: flex;
    width: 150px;
    justify-content: space-between;
}
.socials{
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
    border-radius: 50px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.socials:hover{
    transition: .3s ease-in-out;
    background:  #4372CC;
   
}
.footer-flex{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.footer-line{
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.3);
    margin-bottom: 50px;
}
.copy-div{
    margin: 20px auto;
    display: flex;

}
.copy-div p{
    font-weight: 500;
font-size: 30px;
line-height: 38px;

color: #FFFFFF;
}
.copy{
    width: 40px;
    transform: translateY(10px);
    margin-right: 16.99px;
}

@media screen and (max-width: 575px) {
    .footer-top-left img{
        transform: translateX(-50px);
        
    }
    .footer-top-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer{
        width: 100%;
    }
    .footer-flex{
        flex-direction: column;
      
    }
    .footer-top-socials{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }
    .footer-top-socials h1{
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        width: 211px;
        margin: 0px auto;
        color: white;

color: #FFFFFF;
    }
    .footer-top-right p{
        text-align: left;
        font-weight: 500;
font-size: 16px;
line-height: 20px;
width: 211px;
margin: 24px auto;
color: white;
color: #FFFFFF;
    }
    
    .footer-top-right-line{
        margin-top: 30px;
    }
    .socials-container{
        width: 213px;
        margin: 20px auto;

    }
    .footer-top-left p{
        font-size: 16px;
        text-decoration: none;
        text-align: left;
        width: 211px;
        margin: 10px auto;
      color: white;
    }
    .footer-top-right-line p{
        font-size: 16px;
        text-decoration: none;
        text-align: left;
        width: 211px;
        margin: 20px auto;
        color: rgba(255, 255, 255, 0.7);
    }
    .footer-top-right-line h1{
        font-size: 20px;
        text-align: left;
        width: 211px;
        margin: 10px auto;
    }
    .img-invert{
        filter: invert(50%);
    }
    .copy-div{
        width: 211px;
        margin: 10px auto;
    }
    .copy-div p{
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;

        color: #FFFFFF;
    }
    .copy{
        width: 25px;
    }
}