.navbar-container{
    height: 100px;
    background: #0E2A4A;
    position: fixed;
    width: 100vw;
    z-index: 2000;
}
.navbar{
    width: 1200px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    position: relative;
    justify-content: space-between;
}
.navbar img{
    width: 170px;
    transform: translateX(-30px);
}
.navlink{
   display: flex;
   transform: translateX(100px);
}
.navlink a{
    text-decoration: none;
    color: white;
    margin-left: 60px;
    font-size: 18px;
    align-self: center;
}



@media screen and (max-width: 575px) {
    .navbar-container{
        height: 80px;
    }
    .navbar{
        width: 100%;
        padding: 0px 5%;
    }
    .navlink{
        display: none;
    }
    .navbar img{
        width: 140px;
        transform: translateX(-20px);
    }
}