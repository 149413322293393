.data-tabs-container{
  width: 1200px;
  margin: 150px auto;
}
.data-tabs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.5);
    padding-bottom: 10px;
}
.data-tabs p{
    cursor: pointer;
    /* text-decoration: underline 4px #2E7DDA; */
    text-underline-offset: 12px;
}
.data-tabs-flex{
    height: 800px;
}
.move-btns{
    display: none;
}
@media screen and (max-width: 575px) {
    .data-tabs-container{
        width: 90%;
        margin: 30px auto;
    }
    .data-tabs{
        display: none;
    }
    .move-btns{
        display: block;
        transform: translateY(-40px);
    }
    .data-tabs-flex{
        height: 800px;
    }
    .services-box-left img{
        width: 90%;
    }
    .move-btns button:nth-child(1){
        width: 100px;
        height: 50px;
        border: 1px solid #F8B810 ;
        color: #F8B810;
        background: transparent;
        font-family: 'Lora';
    }
    .move-btns button:nth-child(2){
        width: 100px;
        height: 50px;
       font-family: 'Lora';
        background-color: #F8B810;
        color: black;
        border: none;
        margin-left: 30px;
    }
}