.cto h1{
    text-align: center;
    font-size: 65px;
}
.cto p{
    text-align: center;
    font-size: 35px;
}

@media screen and (max-width: 575px) {
    .cto{
        margin-bottom: 50px;
    }
    .cto h1{
        font-size: 30px;
    }
    .cto p{
        font-size: 16px;

    }
}