.features{
    width: 1200px;
    margin: 150px auto;
    position: relative;

    height: 717px;
}

.features-flex{
    display: flex;
    padding-left:  30px;
    background:rgba(217, 217, 217, 0.1);
}
.features-flex h3{
    display: none;
}
.features-flex p{
    align-self: center;
    font-weight: 500;
font-size: 20px;
line-height: 40px;
color: rgba(0, 0, 0, 0.8);

}
.features-tab{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 50px 50px;
    background: rgba(14, 42, 74, 0.9);
    display: flex;
    justify-content: space-between;
   
}
.features-tab p{
    color: white;
    cursor: pointer;
    display: inline;

}
.features-flex ul{
    align-self: center;
}
.features-flex li{
    align-self: center;
    font-weight: 500;
    font-size: 19px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.8);
    width: 90%;
    margin-left: 10px;
}
.web-p{
    display: block;
    color: white;
    cursor: pointer;
    display: inline;
}
.poly{
    position: absolute;
    bottom: 120px;
    left: 200px;
}
#tri1,
#tri2,
#tri3,
#tri4{
    display: none;
}
@media screen and (max-width: 575px) {
    .web-p{
        display: none;
    }
    .features-flex h3{
        display: block;
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 18px;
 
        color: #FFFFFF;
    }
    /* #tri1,
#rti2,
#tri3,
#tri4{
    display: block;
    z-index: 10;
    position: absolute;
   
} */
    .features-flex img{
       position: absolute;
       top: 0;
       width: 90%;
    }
    .features{
        width: 100%;
        height: auto;
        margin: 0px auto 47px auto;
    }
    .features-tab{
        width: 90%;
        margin: 0px auto;
        overflow-x: auto;
        position: relative;
        padding: 0px 20px;
        bottom: 20px;
    }
    .features-tab p{
        align-self: center;
    }
    .features-flex{
        display: flex;
        flex-direction: column;
        padding: 0px 5%;
        width: 100%;
        margin: 0px auto;
        height: 305px;
    }
    .features-tab-div{
      padding-top: 30px;
    }
    .features-flex p{
   position: absolute;
   z-index: 200;
        font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #FFFFFF;
width: 90%;
padding: 0px 10%;
height: 93.5%;
        position: absolute;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
      
    }
    .features-flex ul{
        transform: translate(0px , 0px);
        font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
width: 90%;

height: 94%;

font-weight: 500;
font-size: 14px;
line-height: 18px;

color: #FFFFFF;
position: absolute;
z-index: 200;
        position: absolute;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding:0px  5%;

    }
    .features-flex ul h3{
       
     
    }
    
    .features-flex li{
       
        z-index: 200;
        font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #FFFFFF;
width: 100%;
margin-bottom: 10px;
        
    }

    .features-tab p{
        display: flex;
        font-size: 12px;
        width: 100%;
        margin-bottom: 30px;
        
    }
    .poly{
        display: none;
    }
 
}
