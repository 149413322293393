.whyus{
    margin: 150px 0px;
    padding: 120px 0px ;
    background: rgba(217, 217, 217, 0.08);
   
}
.whyus-cont{
    width: 1200px;
    margin: 0px auto;
}
.whyus-heading h1{
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
    margin-bottom: 20px;
    transform: translateY(-50px);
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 30px;
}
.whyus-boxes{
    display: flex;
    position: relative;
    height: 700px;
    justify-content: space-between;

}
.why-btn{
    width: 75px;
    cursor: pointer;
}
.whyus-only{
    
    margin: 0px 50px;
}

.whyus-boxes p{
    bottom: 100px;
    left: 0px;
    bottom: -5px;
    padding-top: 8%;
    padding-bottom: 60px;
    backdrop-filter: blur(2px);
    position: absolute;
    font-weight: 600;
font-size: 25px;
width: 100%;
line-height: 41px;
text-align: center;
color: #FFFFFF;
padding: 60px 25px 60px 25px;
background: rgba(0, 0, 0, 0.6);

}
.why-btn:nth-child(1){
 margin-left: -30px;
    width: 60px;
}
.why-btn:nth-child(3){
     width: 60px;
     margin-right: -30px;
}

@media screen and (max-width: 575px) {
    .whyus-cont{
        width: 100%;
        background-color: white;
        padding:  30px 10%;
    }
    .whyus{
        padding: 0;
        margin: 0;
    }
    .whyus-boxes{
        width: 90%;
        margin: 0px auto;
        width: 293px;
        height: 279px;
padding: 20px;
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.06);
    }
    .why-only img{
        width: 100%;
    }
    .why-only{
        display: flex;
        flex-direction: column;
    }
    .why-btn:nth-child(1){
        width: 6%;
       left: -30px;
       top: 100px;
       position: absolute;
       margin: 0;
        
    }
    .why-btn:nth-child(3){
        width: 6%;
        right: -30px;
        top: 100px;
         position: absolute;
         margin: 0;
    }
    .whyus-heading h1{
        font-size: 18px;
        transform: none;
    }
    .whyus-boxes p{
        position: relative;
        color: black;
        text-align: left;
        width: 100%;
    
        left: 0;
        margin-top: 18px;
        padding: 0;
        background-color: transparent;

        font-weight: 500;
font-size: 10px;
line-height: 13px;

color: rgba(32, 31, 31, 0.92);
    }
}