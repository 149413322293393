.services-flex{
    width: 1200px;
    margin: 256px auto 150px auto;
    display: grid;
 
    grid-template-columns: repeat(1,auto);
    justify-content: space-between;
    gap: 150px;
    

}
.services-box-lef{
    display: flex;
    
}
.im-flex{
    margin-right: 20px;
}
.im-flex img{
    width:300px;
    margin-bottom: 20px;
}
.services-box-left img{
    width: 500px;
}
.servi-flex .services-box-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-right: 80px;
}
.servi-flex-odd .services-box-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: 80px;
}
.services-box-right h1{
    font-size: 55px;
    font-weight: 500;
    width: 100%;
    color: rgba(18, 18, 18, 0.93);
   display: block;
 
}
.services-box-right h2{
    font-weight: 400;
    font-size: 400px;
    line-height: 28px;
    position: absolute;
    transform:translate(100px , -210px);
    letter-spacing: 0.004em;

    color: rgba(46, 44, 44, 0.05);
}
.services-box-right p{
    font-size: 21px;
    font-weight: 400;
    width: 90%;
    line-height: 30px;  
    color: rgba(18, 18, 18, 0.6);
    margin: 35px 0px;
}
.services-box-right button{
    width: 250px;
    height: 55px;
    font-family: 'Lora';
    background: #F8B810;
    border: none;
    font-size: 16px;
    font-weight:normal;
}
.servi-flex{
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;
}
.servi-flex-odd{
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;
}
.services-box-right li{
    margin-bottom: 10px;
}
.services-box-right ul{
    margin-left: 20px;
}
.services-box-left{
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.services-box-left h1{
    display: none;
}
@media screen and (max-width: 575px) {
    .servi-flex{
        position: relative;
        flex-direction: column-reverse; 
        margin-bottom: 40px;
    }
    .servi-flex-odd{
        position: relative;
        flex-direction: column; 
        margin-bottom: 40px;
    }
    .servi-flex .services-box-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
       
        margin-right: 00px;
    }
    .services-box-left h1{
        font-weight: 600;
font-size: 20px;
line-height: 26px;
/* identical to box height */

display: block;
color: rgba(18, 18, 18, 0.93);
    }
    .servi-flex-odd .services-box-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
       
        margin-left: 0px;
    }
    .services-flex{
        width: 90%;
        margin: 30px auto;
        gap: 30px;
    }
    .services-flex img{
        width: 100%;
        margin: 30px 0px;
        
    }
    .services-box-right h1{
display: none;
      
     
    }
    .services-box-left img{
        width: 100%;
        margin-top: 30px;
    }
    .services-box-right h2{
   display: none;
    }
    .services-box-right p{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* or 125% */
        margin-top: -18px;
        width: 100%;
        color: rgba(18, 18, 18, 0.6);
    }
    .services-box-right button{
        width: 128px;
height: 39px;
margin-top: -21px;
font-size: 12px;
    }
    .im-flex{
        display: none;
    }
}