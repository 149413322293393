.getstarted{
    display: flex;
    width: 1000px;
    margin: 0px auto;
    padding: 90px 150px;
    background: rgba(248, 184, 16, 0.93);
    justify-content: space-between;
}
.getstarted p{
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.9);
}
.getstarted h2{
    font-weight: 600;
font-size: 30px;
line-height: 36px;
color: rgba(0, 0, 0, 0.9);
}
.getstarted img{
    transition: .2s ease-in-out;
    cursor: pointer;
}
.getstarted img:hover{
    transform: scale(1.2);
    transition: .2s ease-in-out;
}

@media screen and (max-width: 575px) {
    .getstarted{
        width: 100%;
        display: flex;
        padding: 0px 20px;
       height: 75px;
    }
    .getstarted h2{
        align-self: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;

color: rgba(0, 0, 0, 0.9);
    }
    .getstarted p{
        font-weight: 500;
font-size: 12px;
line-height: 15px;
align-self: center;
color: rgba(0, 0, 0, 0.9);
    }
    .getstarted img{
        width: 30px;
    }
  
}