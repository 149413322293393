.contact-body{
    width: 1200px;
    margin: 100px auto;
}
.contactbody-heading h1{
    text-align: center;
    font-weight: 600;
font-size: 50px;
line-height: 64px;
display: block;
color: rgba(18, 18, 18, 0.95);
margin-bottom: 20px;
}
.contactbody-heading p{
    text-align: center;
    font-weight: 400;
font-size: 25px;
line-height: 32px;
color: rgba(0, 0, 0, 0.8);
}
.contact-body-boxes-flex{
    display: flex;
    justify-content: space-between;
    margin: 100px 0px;
}
.contact-body-boxes{
    width: 300px;
    position: relative;
    height: 380px;
}
.contact-body-boxes img{
    width: 300px;
}
.contact-body-boxes p{
    text-align: center;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);

    position: absolute;
    bottom: 60px;
}
.contact-body-boxes img{

}
.contact-body-boxes h4{
    display: none;
}
.contact-body-boxes h3{
    text-align: center;
    transform: translateY(10px);
    display: block;
}
.contact-body-boxes button{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    font-family: 'Lora';
    background: #F8B810;
    border: none;
    font-size: 16px;

  

}

@media screen and (max-width: 575px) {
    .contact-body{
        width: 100%;
        margin: 36px auto;
    }
    .contact-body-boxes-flex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 30px auto;
        
    }
    .contact-body-boxes button{
      left: 0;
      width: 30%;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */
      
      
      color: rgba(0, 0, 0, 0.91);
    }
    .contact-body-boxes img{
        width: 100%;
    }
    .contact-body-boxes{
       margin-bottom: 40px;
       width: 90%;
       height: 380px;
    }
    .contact-body-boxes h4{
      display: block;
      margin: 19px;
      transform: translateX(-5%);
      font-weight: 700;
font-size: 16px;
line-height: 20px;

color: rgba(18, 18, 18, 0.93);
    }
    .contact-body-boxes h3{
        display: none;
    }
    .contactbody-heading{
        padding: 0px 10%;
    }
    .contact-body-boxes p{
        position: none;
        font-weight: 400;
font-size: 12px;
text-align: left;
line-height: 15px;
width: 100%;
color: rgba(0, 0, 0, 0.7);
    }
    .contactbody-heading h1{
        text-align: center;
        font-weight: 600;
        font-size:18px;
        line-height: 64px;
        color: rgba(18, 18, 18, 0.95);
        display: none;
    }
    .contactbody-heading p{
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        transform: translateX(-5%);
        color: rgba(0, 0, 0, 0.8);
    
    color: rgba(0, 0, 0, 0.8);
    }

}