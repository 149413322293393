.testimonials{
    width: 1200px;
    margin: 0px auto;
}
.testi-dia-flex{
    color: white;
    display: flex;
   background: rgba(14, 42, 74, 0.9);
   padding: 100px 50px ;
   justify-content: space-between;
   
   
}
.testi-heading{
    padding:100px;

    background: rgba(217, 217, 217, 0.1);
}
.testi-heading h1{
    font-weight: 600;
font-size: 50px;
line-height: 64px;
text-align: center;
margin-bottom: 20px;
color: rgba(0, 0, 0, 0.9);
}
.testi-heading p{
    font-weight: 400;
    font-size: 20px;
   
    line-height: 29px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
}
.testi-dia{
    position: relative;
    width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.testi-dia h2{
    font-weight: 600;
font-size: 25px;
line-height: 32px;
width: 250px;
/* identical to box height */
position: absolute;
top: 30px;
transform: translateX(20px);
color: #FFFFFF;
}
.testi-dia p{
    position: absolute;
    top: 100px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    width: 200px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
}

.testi-dia img{
    width: 200px;
    position: absolute;
    bottom: 0px;
}

#imgodd{
   width: 300px;  
   bottom: -40px;
}

@media screen and (max-width: 575px) {
    .testimonials{
        width: 100%;
        margin: 0px auto;
       
    }
    .testi-dia{
        width: 100%;
        margin-bottom: 20px;
    }
    .testi-dia h2{
        font-weight: 600;
       
        text-align: center;
      
      transform: translateX(0px);
        
        color: #FFFFFF;
        font-size: 18px;

        margin-bottom: 29px;



    }
    .testi-dia img{
        width: 200px;
        bottom: 50px;
        transform: translateX(0px);
    }
    .testi-heading{
        background-color: white;
        margin: 0px auto;
        padding: 0px 10%;
    }
    
    .testi-heading h1{
        font-weight: 600;
font-size: 16px;
line-height: 30px;


text-align: center;
color: rgba(0, 0, 0, 0.9);
    }
    .testi-heading p{
        font-weight: 400;
        font-size: 13px;
      
        text-align: left;
        color: rgba(0, 0, 0, 0.65);
     margin-bottom: 47px;
line-height: 15px;
  position: relative;

    }
    .testi-dia-flex{
        padding: 73px 10% 53.33px 10%;
        display: flex;
        flex-direction: column;

    }
  
    .testi-dia p{
        font-weight: 500;
font-size: 12px;
line-height: 15px;
width: 246px;
height: 75px;
text-align: left;
transform: translateX(20px);
color: rgba(255, 255, 255, 0.7);
top: 90px;
    }

}