.initiative{
    width: 100vw;
    height: 45vh;
    background: url('../../assets/blo.svg') no-repeat center center/cover;
  
}
.n-cover{
    width: 100%;
    height: 100%;
    padding-top: 50px;
    background: rgba(12, 12, 12, 0.28);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.n-cover h1{
    font-size: 35px;
    color: white;
    text-align: center;
}
@media screen and (max-width: 575px) {

}
