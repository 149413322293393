.infra-top{
    width: 100vw;
    height: 50vh;
    background: url('../../assets/infra.svg') no-repeat center center/cover;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infra-cover{
    width: 100%;
    height: 100%;
    padding-top: 90px;
    background: rgba(12, 12, 12, 0.28);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.infra-top h1{
   text-align: center;
   font-weight: 600;
   font-size: 55px;
   line-height: 70px;
   margin-bottom: 15px;
   color: #FFFFFF;
    z-index: 1000;
}
.infra-top p{
    text-align: center;
    width: 50%;
    font-weight: 600;
font-size: 22px;
line-height: 30px;

color: #FFFFFF;
}
@media screen and (max-width: 575px) {
    .infra-top h1{
        text-align: center;
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
        margin-bottom: 15px;
        color: #FFFFFF;
         z-index: 1000;
         display: block;
     }
  
     .infra-top p{
        display: block;
         text-align: center;
         width: 90%;
         font-weight: 600;
     font-size: 16px;
     line-height: 25px;
     
     color: #FFFFFF;
     }
}