.certifications{
    width: 1200px;
    margin: 150px auto;
    height: 500px;
}
.cirtifications-flex{
    display: flex;
    justify-content: space-between;
}
.certifications-left h1{
    font-size: 40px;
}
.certifications-left p{
    font-weight: 600;
font-size: 22px;
line-height: 32px;
margin-top: 30px;
color: rgba(0, 0, 0, 0.7);
}

.certifications-left img{
    margin-top: 30px;
    width: 300px;
}
#dot-img{
    width: 170px;
    transform: translate(-150px , 0px);
}
.certifications-right{
    display: block;
}
.certifications-right img{
    width: 300px;
}
.certifications-right-mobile{
    display: none;
}
@media screen and (max-width: 575px) {
  
    .certifications{
        width: 90%;
        margin: 80px auto 0px auto;
        height: 200px;
    }
    .certifications-right{
        display: none;
    }
    .certifications-left{
        display: none;
    }
    .certifications-right-mobile{
        display: block;
    }
    .certifications-right-mobile h1{
        font-weight: 600;
font-size: 18px;
line-height: 23px;
/* identical to box height */


color: #000000;
text-align: center;
margin-bottom: 50px;
    }
    .right-mobile-flex{
      display: flex;
        justify-content: center;
        width: 230px;
        margin: 0px auto;
    }
    .certifications-right-mobile img{
        width: 100px;
    }
    .certifications-right-mobile img:nth-child(3){
       transform: translate(70px , 30px);
    }
    .certifications-left h1{
        font-size: 30px;
    }
    .certifications-left p{
        font-size: 16px;
        line-height: 25px;
    }
    .cirtifications-flex{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .certifications-right{
        width: 30px;
    }
    #dot-img{
        transform: translate(100px,-200px);
    }
}