.section-2{
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 150px;
}

.section-2 h1{
    text-align: center;
    font-size: 45px;
    font-weight: 600;
}

.section-2-text{
    font-size: 20px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 70px;
}

.section-pic{
    display: flex;
    justify-content: space-between;
}

.section-pic img{
    width: 590px;
}

@media screen and  (max-width: 575px ) {
    .section-2 h1{
        text-align: center;
        font-size: 40px;
        font-weight: 600;
    }
    
    .section-2-text{
        font-size: 16px;
        font-weight: 400;
        margin-top: 40px;
        margin-bottom: 70px;
    }
    
.section-2{
    width: 90%;
}
    .section-pic{
        flex-direction: column;
        gap: 20px;
    }
    .section-pic img{
        width: 100%;
    }
}